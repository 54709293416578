<template>
	<a
		:href="href"
		target="_blank"
	>
		<IconFacebook v-if="isFacebook" />
		<IconGoogle v-if="isGoogle" />
		<IconNextDoor v-if="isNextDoor" />
	</a>
</template>

<script setup lang="ts">
import { toRefs, computed } from 'vue'
import { FACEBOOK, GOOGLE, NEXT_DOOR } from '@/constants/footer'
import type { SocialLink } from '@/components/social/types'
const props = defineProps({
	link: {
		type: String as PropType<SocialLink>,
		required: true
	}
})

const { link } = toRefs(props)

const href = computed(() => {
	switch (link.value) {
	case FACEBOOK:
		return 'https://www.facebook.com/maverixBB/'
	case GOOGLE:
		return 'https://www.google.com/maps/place/Maverix+Broadband/@39.4997395,-104.710539,11z/data=!4m6!3m5!1s0x6efa953aab4d9d79:0xf16f6e005df2538!8m2!3d39.4997395!4d-104.710539!16s%2Fg%2F11sxyfbj_4?sa=X&ved=2ahUKEwiHhJfJkIT-AhVCOH0KHfGbBMYQ_BJ6BQiqARAI&coh=164777&entry=tt&shorturl=1'
	case NEXT_DOOR:
		return 'https://nextdoor.com/pages/maverix-broadband-parker-co/'
	default:
		return 'https://www.google.com/maps/place/Maverix+Broadband/@39.4997395,-104.710539,11z/data=!4m6!3m5!1s0x6efa953aab4d9d79:0xf16f6e005df2538!8m2!3d39.4997395!4d-104.710539!16s%2Fg%2F11sxyfbj_4?sa=X&ved=2ahUKEwiHhJfJkIT-AhVCOH0KHfGbBMYQ_BJ6BQiqARAI&coh=164777&entry=tt&shorturl=1'
	}
})

const isFacebook = computed(() => {
	return link.value === FACEBOOK
})
const isGoogle = computed(() => {
	return link.value === GOOGLE
})
const isNextDoor = computed(() => {
	return link.value === NEXT_DOOR
})

</script>
